<!--
 * @Author: huwanjun
 * @Date: 2021-04-12 13:42:54
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-19 11:39:24
 * @Description: 新增价格弹框
-->

<template>
  <div class="AddPrice">
    <a-form-model ref="priceForm" v-bind="form" :colon="false" :rules="rules" :model="formdata">
      <!-- 服务类型 -->
      <a-form-model-item prop="serviceType" :label="$t('priceList.service_type')">
        <a-select
          v-model="formdata.serviceType"
          @change="onSelectType"
          :placeholder="$t('priceList.service_type_holder')"
        >
          <a-select-option v-for="(item, index) in serviceType" :key="index" :value="item.dictValue">{{
            item.dictLabel
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <!-- 行业领域 -->
      <a-form-model-item
        prop="field"
        :label="$t('priceList.industry_field')"
        :rules="selected ? undefined : [validate.required]"
      >
        <a-input default-value="---" v-if="selected" :disabled="selected"></a-input>
        <a-cascader
          v-model="formdata.field"
          :options="industryList"
          :fieldNames="fielName"
          :placeholder="$t('newPline.industry_field_holder')"
          :disabled="selected"
          v-else
        ></a-cascader>
      </a-form-model-item>
      <!-- 文档类型 -->
      <a-form-model-item prop="documentType" :label="$t('priceList.doc_type')">
        <a-select v-model="formdata.documentType" :placeholder="$t('priceList.doc_type_holder')">
          <a-select-option v-for="(item, index) in docType" :key="index" :value="item.dictValue">{{
            item.dictLabel
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <!-- 源语言 -->
      <a-form-model-item
        prop="sourceCode"
        :key="selected"
        :label="$t('priceList.source_language')"
        :rules="selected ? undefined : [validate.required]"
      >
        <a-input default-value="---" v-if="selected" :disabled="selected"></a-input>
        <a-select
          show-search
          v-model="formdata.sourceCode"
          :disabled="selected"
          :placeholder="$t('priceList.source_language_holder')"
          :filter-option="filterOption"
          v-else
        >
          <a-select-option v-for="(item, index) in langList" :key="index" :value="item.code">{{
            item.name
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <!-- 目标语言 -->
      <a-form-model-item
        prop="targetCode"
        :key="~~selected"
        :label="$t('priceList.target_language')"
        :rules="selected ? undefined : [validate.required]"
      >
        <a-input default-value="---" v-if="selected" :disabled="selected"></a-input>
        <a-select
          show-search
          v-model="formdata.targetCode"
          :disabled="selected"
          :placeholder="$t('priceList.target_language_holder')"
          :filter-option="filterOption"
          v-else
        >
          <a-select-option v-for="(item, index) in langList" :key="index" :value="item.code">{{
            item.name
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <!-- 价格 -->
      <a-form-model-item prop="price" :label="$t('priceList.price')">
        <a-input v-model="formdata.price" :placeholder="$t('priceList.price_holder')"></a-input>
      </a-form-model-item>
      <!-- 单位 -->
      <a-form-model-item prop="unit" :label="$t('priceList.unit')">
        <a-select v-model="formdata.unit" :placeholder="$t('priceList.unit_holder')">
          <a-select-option v-for="(item, index) in unit" :key="index" :value="item.dictValue">{{
            item.dictLabel
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item>
        <a-button v-if="edit" type="primary" class="submit-btn" @click="onEditSubmit" :loading="editLoading">{{
          $t('submit')
        }}</a-button>
        <a-button v-else type="primary" class="submit-btn" @click="onSubmit" :loading="subLoading">{{
          $t('submit')
        }}</a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import numeral from 'numeral'
import { mapState } from 'vuex'
const { validate } = window.$g

export default {
  name: 'AddPrice',
  props: ['id', 'currency', 'priceInfo', 'edit'],
  data() {
    return {
      validate,
      form: {
        labelCol: { flex: '80px' },
        wrapperCol: { flex: 'auto' },
      },
      // options
      fielName: {
        value: 'dictValue',
        label: 'dictLabel',
        children: 'children',
      },
      // 新增价格表单
      formdata: {
        serviceType: undefined,
        field: undefined,
        documentType: undefined,
        sourceCode: undefined,
        targetCode: undefined,
        price: undefined,
        unit: undefined,
      },
      // 表单验证
      rules: {
        serviceType: [validate.required],
        // field: [validate.required],
        documentType: [validate.required],
        // sourceCode: [validate.required],
        // targetCode: [validate.required],
        price: [validate.float_3, validate.required],
        unit: [validate.required],
      },
      selected: false,
      subLoading: false,
      editLoading: false,
      numeral,
    }
  },
  computed: {
    serviceType() {
      return this.$store.getters['app/getDictByType']('SERVICE_TYPE')
    },
    docType() {
      return this.$store.getters['app/getDictByType']('DOC_TYPE')
    },
    ...mapState('app', {
      langList: (state) => state.langList,
    }),
    ...mapState('app', {
      industryList: (state) => state.industryList,
    }),
    unit() {
      return this.$store.getters['app/getDictByType']('SALE_UNIT')
    },
  },
  mounted() {
    // TODO: 处理edit
    if (this.edit) {
      // this.selected = true
      this.getPrice()
    }
  },
  methods: {
    onSubmit() {
      this.$refs.priceForm.validate(async (valid) => {
        if (valid) {
          this.subLoading = true
          try {
            // eslint-disable-next-line no-unused-vars
            const data = await this.$http('newPrice', {
              agencyId: this.id,
              currency: this.currency,
              field: this.formdata.field ? this.formdata.field[this.formdata.field.length - 1] : '',
              documentType: this.formdata.documentType,
              price: this.formdata.price,
              serviceType: this.formdata.serviceType,
              unit: this.formdata.unit,
              id: this.formdata.id,
              targetCode: this.formdata.targetCode,
              sourceCode: this.formdata.sourceCode,
            })
            this.subLoading = false
            // TODO: 触发父组件，更新数据
            this.$message.success(this.$t('prompt.create_success'))
            this.$emit('addPrice')
          } catch (err) {
            this.subLoading = false
            this.$httpNotify(err)
          }
        }
      })
    },
    async onEditSubmit() {
      this.$refs.priceForm.validate(async (valid) => {
        if (valid) {
          this.editLoading = true
          try {
            // eslint-disable-next-line no-unused-vars
            const data = await this.$http('editPrice', {
              agencyId: this.id,
              currency: this.currency,
              field: this.formdata.field ? this.formdata.field[this.formdata.field.length - 1] : '',
              documentType: this.formdata.documentType,
              price: this.formdata.price,
              serviceType: this.formdata.serviceType,
              unit: this.formdata.unit,
              id: this.formdata.id,
              targetCode: this.formdata.targetCode ? this.formdata.targetCode : '',
              sourceCode: this.formdata.sourceCode ? this.formdata.sourceCode : '',
            })
            this.editLoading = false
            this.$emit('addPrice')
            this.$message.success(this.$t('prompt.edit_success'))
          } catch (err) {
            this.$httpNotify(err)
          }
        }
      })
    },
    getDictTree(val) {
      let result = ''
      if (val === null) {
        result = ''
      } else {
        const str = val?.split('-')[0] + '-' + val?.split('-')[1]
        result = [str, val]
      }
      return result
    },
    async getPrice() {
      try {
        const data = await this.$http({ key: 'getPriceById', params: { id: this.priceInfo.id } })
        this.formdata = data
        this.formdata.field = this.getDictTree(data.field)
        // 判断是否可选
        if (this.formdata.serviceType !== '1-1' && this.formdata.serviceType !== '1-2') {
          this.selected = true
          this.formdata.sourceCode = undefined
          this.formdata.targetCode = undefined
          this.formdata.field = undefined
        }
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    onSelectType(val) {
      if (val !== '1-1' && val !== '1-2') {
        this.selected = true
      } else {
        this.selected = false
      }
      this.formdata.field = undefined
      this.formdata.sourceCode = undefined
      this.formdata.targetCode = undefined
    },
    // 筛选过滤
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
  },
}
</script>

<style lang="less" scoped>
.AddPrice {
  padding: 5px 96px;
  .submit-btn {
    min-width: 100px;
    margin-left: 74px;
  }
  /deep/.ant-form-item {
    margin-bottom: 15px;
    display: flex;
  }
}
</style>
